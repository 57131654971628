.App {
  font-family: Roboto;
}

.contact {
  padding-left: 40px;
}

.contact > a > img {
  padding: 10px 20px 10px 0px;
  height: 45px;
}

.custom-navbar-brand > a {
  color: white;
  /* display: flex !important;
  align-items: center; */
  font-size: 1.6em !important;
}

.custom-navbar-brand > a:hover {
  text-decoration: none;
  color: white;
}

.custom-navbar {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 5px 10px #666666;
  min-height: 69px;
  background-color: #5497ea;
}

.nav-item {
  display: flex;
  align-items: center;
}

.navbar > * {
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.menu-item {
  color: white;
  text-decoration: none;
  padding-right: 30px;
}

.menu-item:hover {
  text-decoration: none;
  color: #1c76e5;
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding: 15px 15px !important;
  }
}

@media only screen and (max-width: 550px) {
  .menu-item {
    font-size: 1em;
    padding-right: 15px !important;
  }
  .contact {
    padding-left: 0;
  }
}
