.accomplishments {
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0px;
}

.accomplishment {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px 0px;
}

.accomplishment-info {
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 540px;
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .accomplishment {
    margin: 0px !important;
  }
}

.accomplishment-title {
  font-size: 1.2em;
  font-weight: 600;
  color: #1c76e5;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-width: 250px;
  min-height: 250px;
  border-radius: 10px;
  box-shadow: #d9d9d9 5px 5px;
  margin: 5px 50px 20px 50px;
}

.card:hover {
  background-color: #d9d9d9;
}
