body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: Roboto;
}

.contact {
  padding-left: 40px;
}

.contact > a > img {
  padding: 10px 20px 10px 0px;
  height: 45px;
}

.custom-navbar-brand > a {
  color: white;
  /* display: flex !important;
  align-items: center; */
  font-size: 1.6em !important;
}

.custom-navbar-brand > a:hover {
  text-decoration: none;
  color: white;
}

.custom-navbar {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 5px 10px #666666;
  min-height: 69px;
  background-color: #5497ea;
}

.nav-item {
  display: flex;
  align-items: center;
}

.navbar > * {
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.menu-item {
  color: white;
  text-decoration: none;
  padding-right: 30px;
}

.menu-item:hover {
  text-decoration: none;
  color: #1c76e5;
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding: 15px 15px !important;
  }
}

@media only screen and (max-width: 550px) {
  .menu-item {
    font-size: 1em;
    padding-right: 15px !important;
  }
  .contact {
    padding-left: 0;
  }
}

.round-headshot {
  width: 250px;
}

.anchor {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}

.about-me-section {
  padding-top: 70px;
}

.button {
  width: 100px;
  height: 20px;
  color: #1c76e5;
  background-color: transparent;
  border: 1px #1c76e5 solid;
  border-radius: 5px;
  text-decoration: none;
  line-height: 20px;
  padding: 10px;
}

.button:hover {
  background-color: rgba(28, 118, 229, 0.1);
  text-decoration: none;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10% 30px 10%;
}

.section > * {
  margin: 20px 0px;
}

.lists {
  display: flex;
}

.off-white {
  background-color: #f5f5f5;
}

.section > p {
  max-width: 1000px;
}

.section > p > span {
  font-weight: 500;
}

.sectionTitle {
  text-align: center;
  line-height: 0.1em;
  padding-bottom: 30px;
  padding-top: 0px;
  font-weight: 500;
  font-size: 1.6em;
}

.sectionTitle span:before,
.sectionTitle span:after {
  content: "";
  position: absolute;
  height: 0px;
  border-bottom: 2px solid black;
  top: 0;
  width: 50px;
}

.sectionTitle span {
  display: inline-block;
  position: relative;
}

.sectionTitle span:before {
  right: 100%;
  margin-right: 15px;
}
.sectionTitle span:after {
  left: 100%;
  margin-left: 15px;
}

.sectionTitle span {
  padding: 0 10px;
}

.section-subtitle {
  font-size: 1.2em;
  font-weight: 600;
  color: #1c76e5;
}

.element:before {
  content: "\2022";
}

a {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.email {
  color: #1c76e5;
}

.connections {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
}

.connections > * {
  margin: 0px 25px;
}

.fadeIn {
  -webkit-animation-name: slide-up;
          animation-name: slide-up;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}

.fadeInButton {
  -webkit-animation-name: slide-up-button;
          animation-name: slide-up-button;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}

@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-up-button {
  from {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-button {
  from {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .about-me-section {
    padding-top: 120px;
  }
  .anchor {
    top: -120px !important;
  }
}

@media only screen and (max-width: 640px) {
  .about-me-section {
    padding-top: 143px;
  }
  .anchor {
    top: -143px !important;
  }
}

@media only screen and (max-width: 600px) {
  .section {
    padding: 30px 5%;
  }
  .sectionTitle span {
    font-size: 0.7em;
  }
  .sectionTitle span {
    padding: 0 0px !important;
  }

  .sectionTitle span:before,
  .sectionTitle span:after {
    width: 20px;
  }

  .sectionTitle span:before {
    margin-right: 10px !important;
  }
  .sectionTitle span:after {
    margin-left: 10px !important;
  }

  h3 {
    font-size: 1em;
  }
}

.accomplishments {
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0px;
}

.accomplishment {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px 0px;
}

.accomplishment-info {
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 540px;
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .accomplishment {
    margin: 0px !important;
  }
}

.accomplishment-title {
  font-size: 1.2em;
  font-weight: 600;
  color: #1c76e5;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-width: 250px;
  min-height: 250px;
  border-radius: 10px;
  box-shadow: #d9d9d9 5px 5px;
  margin: 5px 50px 20px 50px;
}

.card:hover {
  background-color: #d9d9d9;
}

